<template>
  <div>
    <div class="wrap">
      <div class="con_top">
        <h2><span>■</span> 공지사항등록</h2>
      </div>
      <div class="con">
        <div class="con_table">
          <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
            <tbody>
              <tr>
                <th style="width:111px;">제목<span class="red"> *</span></th>
                <td class="left" style="padding-left: 0"><input type="text" v-model="boardTitle" class="ml-20 w-94per" /> <br /></td>
                <th style="width:111px;">중요</th>
                <td class="left" style="padding-left: 1em">중요공지 표시 <input name="중요표시" v-model="boardImportant" type="checkbox" true-value="Y" false-value="N" /></td>
              </tr>
              <tr>
                <th style="width:111px;">표시 구분 <span class="red">*</span></th>
                <td class="left">
                  <input name="all" v-model="boardGubun" value="공용" type="radio" />공용 <input name="root" v-model="boardGubun" value="본원센터" class="ml-3" type="radio" />본원센터
                  <input name="sub" v-model="boardGubun" value="협약센터" class="ml-3" type="radio" />협약센터
                </td>
                <th style="width:111px;">첨부파일</th>
                <td class="left" style="padding-left: 0">
                  <button type="button" class="default-btn ml-20" name="fileUpload" @click="$refs.boardFile.click()">파일 선택</button>
                  <input v-show="false" ref="boardFile" type="file" @change="uploadBoardFile($event, 'boardFile')" />
                  <span v-if="boardFile.name" class="ml-20" style="cursor:pointer" @click="detailFile()">{{ boardFile.name }}</span>
                </td>
              </tr>
              <tr>
                <th>내용 <span class="red">*</span></th>
                <td class="left" colspan="3">
                  <quill-editor v-model="boardContents" />
                </td>
              </tr>
            </tbody>
          </table>
          <div class="btns3 mgB80">
            <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
            <a class="btn_cancle pointer" @click="registerBoard()">등록</a>
          </div>
        </div>
      </div>
      <div id="result"></div>
    </div>
  </div>
</template>

<script>
import { EmptyCheck } from "@/utils/validation.js";
//에디터 컴퍼넌트
import { quillEditor } from "vue-quill-editor";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
export default {
  name: "admin-notice-register",

  components: {
    quillEditor,
  },

  data: () => ({
    boardTitle: "",
    boardGubun: "",
    boardContents: "",
    boardFile: "",
    name: "",
    boardImportant: "",
    chStatus: -1,
  }),

  mounted() {},

  methods: {
    removeBoardFile() {
      this.boardFile = "";
      this.boardList[0].board_file_name = "";
      this.boardList[0].board_file_url = "";
      this.boardList[0].board_file_url = null;
      this.chStatus = 1;
    },
    // 첨부 파일 업로드
    uploadBoardFile(event, fileType) {
      // 포맷 검사
      var allowFormat = ["jpg", "jpeg", "png", "gif", "JPG", "JPEG", "PNG", "GIF", "pdf", "hwp", "xlsx", "docx", "PDF", "HWP", "XLSX", "DOCX"];
      var checked = false;
      for (var item of allowFormat) {
        if (event.target.files[0].name.includes(item)) {
          checked = true;
          break;
        } else {
          continue;
        }
      }
      if (checked) {
        // 용량 검사
        if (event.target.files[0].size > 1048576 * 3) {
          alert("3MB 이하만 업로드 가능합니다.");
          this.$refs[fileType].value = "";
          return false;
        }
        this[fileType] = event.target.files[0];
        this.$refs[fileType].value = "";
      } else {
        alert("파일 포맷은 jpg, gif, png, jpeg, pdf, hwp, xlsx, docx 형식만 가능합니다.");
        this.$refs[fileType].value = "";
        return false;
      }
    },
    // 이전 페이지로 이동
    cancel() {
      history.back();
    },
    registerBoard() {
      // 빈값 체크
      if (!EmptyCheck(this.boardTitle, "제목을")) {
        return false;
      }
      if (this.boardFile !== "") {
        this.chStatus = 1;
      } else {
        this.boardFile = null;
        this.chStatus = -1;
      }
      // if (!EmptyCheck(this.boardGubun, '표시 구분을')) {
      //     return false
      // }

      if (!EmptyCheck(this.boardContents, "내용을")) {
        return false;
      }
      //공지 등록 api 호출

      // console.log(this.content)
      // document.getElementById('result').innerHTML=this.content;

      var params = new FormData();
      params.append("boardTitle", this.boardTitle);
      params.append("boardContents", this.boardContents);
      params.append("boardImportant", this.boardImportant);

      // 상담서비스 checkbox => string 매핑작업
      var boardGubun = "";
      for (var key of Object.keys(this.boardGubun)) {
        if (this.boardGubun[key].use) {
          if (boardGubun === "") {
            boardGubun += this.boardGubun[key].name;
          } else {
            boardGubun += "/" + this.boardGubun[key].name;
          }
        }
      }
      params.append("boardGubun", this.boardGubun);
      params.append("chStatus", this.chStatus);

      // 파일 빈 binary로 추가해놓기
      params.append("boardFile", new Blob());
      if (this.boardFile !== "") {
        params.delete("boardFile");
        params.append("boardFile", this.boardFile);
      }
      if (this.btnDisabled) {
        alert("저장중입니다. 잠시만 기다려주세요.");
        return false;
      }
      this.btnDisabled = true;
      this.axios
        .post("/api/v1/board/insertBoard", params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log("res", res);
          if (res.data.err === 403) {
            alert("권한이 없습니다.");
            history.back();
          } else if (res.data.err === 210) {
            alert("중요게시물 등록가능 갯수를 초과하였습니다.");
          } else if (res.data.err === 106) {
            alert("에러코드 106 추가 msg : 임의로 [중요]표시를 할 수 없습니다.");
          } else if (res.data.err === 105) {
            alert("같은 이름으로 등록된 공지가 있습니다.");
          } else {
            alert("등록되었습니다.");
            this.$router.push("/origin/notice");
          }
        })
        .catch((err) => {
          alert(err);
          console.log(err);
          if (err.response.data.path == "/login/msg") {
            alert("세션이 끊어졌습니다. 로그인 페이지로 이동합니다.");
            this.$router.push("/login");
          } else {
            alert("관리자에게 문의하세요");
          }
        })
        .finally(() => {
          this.btnDisabled = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
